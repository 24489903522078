body {
  background-color: black;
}

.container,
.container-fluid {
  background-color: black;
}

.alert-dismissible {
  padding-right: 10px;
}

.alert.alert-success span {
  font-family: Inter;
  font-size: 18px;
}

.navbar.bg-body-tertiary {
  background-color: black !important;
  border-bottom: 1px solid white;
}

.navbar.bg-body-tertiary .navbar-brand,
.navbar.bg-body-tertiary .nav-link {
  font-family: Inter;
  font-size: 16px;
  color: #fff;
  padding-left: 30px;
}

.logo {
  max-width: 200px;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background: #92f92f;
  border-radius: 100%;
}

.btn-green {
  font-family: Inter;
  font-size: 18px;
  padding: 8px 30px;
  color: #92f92f;
  background-color: transparent;
  border-color: #92f92f;
  border-radius: 25px;
}

.btn-green:hover {
  color: #ffffff;
  background: #92f92f !important;
}

.btn-pink {
  font-family: Inter;
  font-size: 18px;
  color: #e2866d;
  padding: 8px 38px;
  background-color: black !important;
  border-color: #e2866d;
  border-radius: 25px !important;
}

.btn-pink:hover {
  color: #fff !important;
  background-color: #e2866d !important;
  border-color: #e2866d;
}

.btn-transparent-invert,
.btn-assign-glyphs {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  padding: 8px 27px !important;
  color: #fff;
  background-color: transparent;
  border: 1px solid white !important;
  border-radius: 25px;
}

.btn-transparent-invert:hover,
.btn-assign-glyphs:hover {
  color: #363636;
  background: #fff;
}

.btn-padding-overide {
  padding: 8px 0px !important;
}

.btn-delete-invert {
  border-radius: 25px;
  padding: 8px 30px !important;
  background-color: black !important;
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}

.btn-delete-invert:hover {
  color: #fff !important;
  background: #dc3545 !important;
}

.bg-active {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 8px;
  color: #06b203;
  background: #c8e7c8;
  border-radius: 4px;
}

.bg-inactive {
  background-color: #e7c8c8 !important;
  color: #a20505 !important;
  font-weight: normal !important;
}

.page-heading {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.page-text {
  color: white;
}

p {
  font-family: Inter;
  font-size: 14px;
}

.search-bar {
  background-color: black !important;
  border-color: white !important;
  color: white !important;
}

.page-sort-filter label,
.page-sort-filter .dropdown button {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
}

.page-sort-filter .dropdown button {
  background: transparent;
  width: 100%;
  margin: 0;
}

.page-sort-filter .dropdown-toggle {
  line-height: 10px !important;
  border-color: black !important;
  margin-bottom: 7px;
}

.page-sort-filter .dropdown-toggle::after {
  visibility: hidden;
  display: none;
}

/* =========***==========
Modal CSS
=========***========== */

.modal-content {
  color: white;
  background-color: black;
  box-shadow: none;
  border: none;
  margin: 5px auto;
  padding: 20px 40px;
  border: 1px solid white;
  border-radius: 25px;
}

.modal-dialog {
  max-width: 1030px !important;
}

.modal-header .close {
  position: absolute;
  top: 0;
  right: 20px;
  color: white;
  text-shadow: none;
}

.modal-header {
  border: none;
}

.modal-title {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.modal-body {
  border: none;
}

.modal-body .form-label,
.form-label {
  font-family: Inter;
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.modal-body .form-control,
.modal-body .form-select {
  padding: 10px 12px;
  margin-bottom: 25px;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-color: white;
  border-radius: 10px;
}

.modal-body .form-select {
  background-color: black;
  color: white;
  border-color: white;
  border-radius: 12px;
}

.modal-body .form-select {
  background-color: black;
  color: white;
  border-color: white;
  border-radius: 12px;
}

.modal-body .form-control::placeholder {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400 !important;
  color: rgba(255, 255, 255, 0.5);
}

.modal-footer {
  border-top: none !important;
}

.modal-close-btn {
  background-color: black;
  border: none;
  font-size: 2em;
}

/* =========***==========
Login Page CSS
=========***========== */

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translateY(-12%);
}

.login-card {
  background-color: black;
}

.login-form {
  padding: 20px;
}

.login-form label,
.login-form .form-control {
  color: white;
}

.login-form .form-control {
  border-color: white;
  background-color: black;
  padding: 10px 25px;
  border-radius: 30px;
  border: 1px solid #fff;
  margin-bottom: 10px;
}

.login-form .form-control::placeholder {
  color: white;
}

.login-form .btn-parrot {
  font-family: "Inter", sans-serif;
  background-color: #92f92f !important;
  border-color: #92f92f !important;
  color: black !important;
  padding: 7px 20px !important;
  border-radius: 25px !important;
  width: 130px !important;
}

/* ===========***============
Material CSS
===========***============ */

.material-card-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.material-card {
  background-color: #7d7fa8;
  color: white;
  min-height: 320px;
  height: 100%;
  border: none;
}

.material-card img {
  width: 100%;
  min-height: 240px;
  max-height: 240px;
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.material-card .card-body span {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.material-card .card-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.assign-material-checkbox {
  right: 0;
  top: 10px;
}

.assign-material-checkbox .form-check-input {
  background-color: black;
  width: 1.2em;
  height: 1.2em;
  border: 1px solid #7d7fa8;
  box-shadow: 0 0 0 1px black, 0 0 0 2px white;
}

.assign-material-checkbox .form-check-input:checked {
  background-color: black;
  border: 1px solid #7d7fa8;
  box-shadow: 0 0 0 1px black, 0 0 0 2px white;
}

.material-checkbox {
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
}

.material-checkbox .form-check-input {
  background-color: #ffffff;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #000000;
  border-radius: 7px;
}

.material-checkbox .form-check-input:checked {
  background-color: #d1d3e7;
  border: 1px solid #7d7fa8;
  box-shadow: 0 0 0 2px black, 0 0 0 4px white;
}

.assign-materials-search-bar {
  padding-left: 20px !important;
  margin-bottom: 0 !important;
  border-right: none !important;
  border-radius: 30px 0px 0px 30px !important;
}

.assign-materials-search-bar::placeholder {
  font-family: Inter;
  font-size: 16px !important;
  color: #fff !important;
}

.assign-materials-search-button {
  padding: 10px 15px;
  background-color: #92f92f !important;
  border: 1px solid white !important;
  border-radius: 0px 30px 30px 0px;
  vertical-align: middle !important;
}

.search-materials-textbox {
  padding: 8px 38px;
  margin-bottom: 0 !important;
  color: white !important;
  background-color: black !important;
  border-radius: 25px !important;
}

.search-materials-textbox:focus {
  outline: 0;
  border-color: white;
  box-shadow: none;
}

.search-materials-textbox::placeholder {
  font-family: Inter;
  font-size: 18px;
  color: white !important;
}

.material-image {
  margin-right: 15px !important;
}

.material-image-thumbnail {
  margin-right: 25px !important;
  height: 72px !important;
  width: 72px !important;
}
.material-item {
  margin-top: 30px !important;
}

.dalle-image-preview {
  width: 100%;
  max-width: 420px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.dalle-image-preview-container {
  min-height: 400px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dalle-image-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.material-upload-btn {
  font-family: Inter;
  background-color: black !important;
  color: white !important;
  border: 2px solid white !important;
  padding: 15px 20px !important;
  margin-bottom: 30px;
  border-radius: 15px;
  height: 80px;
}

.container-for-loading {
  height: 100vh;
}

.material-filter {
  width: 100%;
}

.material-filters .dropdown button {
  width: 100%;
  font-family: Inter;
  font-size: 18px;
  padding: 14px;
  color: black !important;
  background: white !important;
}

.material-filters .dropdown-toggle {
  color: white;
  border-radius: 30px !important;
  line-height: 10px !important;
  border-color: grey !important;
  margin-bottom: 10px;
}

.material-filters .dropdown-toggle::after {
  text-align: right;
  float: right;
  margin-top: 2px;
}

.material-sort-filter .dropdown-toggle::after {
  visibility: hidden;
  display: none;
}

.material-text h6 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px;
}

.material-text p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.material-details-content {
  padding: 30px 0;
  height: auto;
}

.material-details-content h2 {
  padding-bottom: 50px;
}

.material-details-content p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.material-details-content p span {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  padding-left: 10px;
  color: #fff;
}

.material-details-content .badge {
  margin-bottom: 5px;
}

.material-details-btn {
  padding-top: 50px;
}

.material-details-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 700px;
  margin-top: -20px;
}

.material-details-img img {
  max-width: 100%;
  max-height: 700px;
}

/* ==========***==========
Gylph CSS
==========***========== */

.assign-glyphs-container {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 15px;
  padding-left: 5px;
}

.glyph-card {
  background-color: black !important;
  border: 3px solid #363636;
  padding: 5px;
  box-shadow: 3px 3px 7px 1px #363636, -3px 3px 7px #363636;
  border-radius: 20px;
}

.glyph-card .card-header {
  padding: 16px 0 0 21px !important;
}

.glyph-card-header {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  color: #fff !important;
}

.glyph-card-body img {
  width: 89px;
  height: 89px;
  border-radius: 10px;
}

.glyph-card-body {
  font-family: Inter;
  font-size: 12px;
  color: #fff;
}

.gylph-details-img {
  max-width: 312px;
  min-height: 312px;
  max-height: 312px;
  height: 100%;
  object-fit: cover;
}

.gylph-download-btn {
  padding: 8px 38px !important;
}

.gylph-details-content h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 40px;
}

.gylph-details-content p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.gylph-details-content p span {
  font-weight: 400;
  padding-left: 10px;
}

/* ===========***============
Responsive CSS
===========***============ */

@media (min-width: 993px) and (max-width: 1200px) {
  .material-card-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .material-card-wrapper {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 301px) and (max-width: 768px) {
  .navbar-brand,
  .nav-link {
    padding-left: 0 !important;
  }

  .logo {
    max-width: 150px;
  }

  .navbar-collapse {
    padding-bottom: 20px;
  }

  .navbar-toggler-icon {
    background-image: url(/public/images/bar-icon.svg);
  }

  .page-heading,
  .modal-title {
    font-size: 24px;
  }

  .material-card-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .material-card .card-body span,
  .material-card .card-text {
    font-size: 16px;
  }

  .modal-content {
    padding: 20px 10px;
  }

  .material-details-content {
    padding: 30px 10px;
  }

  .material-details-btn {
    padding-bottom: 30px;
  }

  .material-details-img {
    justify-content: start;
    margin-top: 0px;
  }
}

.css-13cymwt-control {
  background: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 10px !important;
}

.css-qbdosj-Input input:focus {
  background: transparent !important;
}

.css-qbdosj-Input {
  padding: 5px 0px !important;
}

.css-1nmdiq5-menu {
  background: #ffffff !important;
}

.css-1nmdiq5-menu div {
  color: #000000 !important;
  background: transparent;
}
